<template>
  <div>
    <v-card :class="{ 'card-table_container--mobile' : $vuetify.breakpoint.xsOnly }">
      <v-data-table
        :headers="headers"
        :items="referrals"
        :server-items-length="pagination.itemsLength"
        :options.sync="pagination"
        :loading="loading"
        :footer-props="{'items-per-page-options':[10, 50, 100]}"
        @update:options="tablePaginated"
      >
        <template v-slot:item="{ item }">
          <tr v-if="$vuetify.breakpoint.smAndUp">
            <td>{{ typeFormat(item.referral_type) }}</td>
            <td>{{ item.business_name }}</td>
            <td>{{ programName(item.program_name) }}</td>
          </tr>
          <v-card
            v-else
            class="my-2 mx-1"
          >
            <v-card-subtitle class="pb-2">{{ typeFormat(item.referral_type) }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="py-0">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>
                      mdi-domain
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.business_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="item.program_name">
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file-document
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ programName(item.program_name) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'ReferralsList',
    components: {
    },
    data() {
      return {
        search: '',
        pagination: {
          page: 1,
          itemsLength: 0,
          itemsPerPage: 10
        },
        loading: false,
        headers: [
          {text: 'Type', value: 'type', sortable: false},
          {text: 'Business name', value: 'business', sortable: false},
          {text: 'Program name', value: 'programname', sortable: false}
        ],
        programItems: [],
        referrals: []
      }
    },
    created() {
      this.getPrograms()
      .then((response) => {
        this.programItems = response.data.results
      });
    },
    methods: {
      ...mapActions(['getReferrals', 'deleteReferral', 'getPrograms']),
      getReferralsList(params) {
        this.loading = true
        this.getReferrals({
          limit: params.limit,
          offset: params.offset
        })
        .then((response) => {
          this.referrals = response.data.results
          this.pagination.itemsLength = response.data.count
          this.loading = false
        }, (error) => {
          console.log('error', error)
        });
      },
      tablePaginated(event) {
        this.getReferralsList({limit: event.itemsPerPage, offset: ((event.page - 1)*event.itemsPerPage)})
      },
      deleteItem(id) {
        this.deleteReferral({id})
      },
      viewReferralDetail(event, item) {
        if (event.target.nodeName == 'SPAN') {
          this.$router.push(`edit/${item.id}`)
        } else {
          this.$router.push(`view/${item.id}`)
        }
      },
      statusFormat(item) {
        return item == 0 ? 'Active' : 'Closed';
      },
      typeFormat(item) {
        return item == 1 ? 'Internal' : 'External';
      },
      programName(id) {
        return id ? this.programItems.find((entry) => entry.id == id).name : '';
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/styles/variables.scss';
  tr {
    //cursor: pointer;
  }
  ::v-deep {
    .v-data-table-header th {
      text-transform: uppercase;
    }
    tbody td a {
      color: inherit;
      &:hover {
        font-weight: bold;
        color: $primary-color;
      }
    }
    td {
      &:first-child {
        min-width: 100px;
      }
      min-width: 150px;
      @media only screen and (max-device-width: 768px) {
        padding: 0 8px!important
      }
    }
  }
</style>
